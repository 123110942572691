<template>
    <div>
		<BHLoading :show="loading" />
		<div>
			<a-form-model-item label="Select which cities to show">
				<a-select
					v-model="brokerObj.cities"
					mode="multiple"
					style="width: 100%"
					placeholder="Select cities"
					@change="onCityChange"
				>
					<template v-for="(city,cityI) in cities">
						<a-select-option :value="city.value" :label="city.label" :key="city+cityI">
							{{ city.label }}
						</a-select-option>
					</template>
				</a-select>
			</a-form-model-item>
		</div>
		<div v-if="brokerObj.cities && brokerObj.cities.length">
			<a-form-model-item label="Select which builders to exclude">
				<a-select
					v-model="brokerObj.builders"
					mode="multiple"
					style="width: 100%"
					placeholder="Select builders to exclude"
					optionFilterProp="label"
				>
					<template v-for="(builder,builderI) in builders">
						<a-select-option :value="builder.id" :label="builder.name" :key="builder+builderI">
							<strong>{{ builder.name }}</strong> - <i>{{ builder.locationOBJ.cityLabel }}</i>
						</a-select-option>
					</template>
				</a-select>
			</a-form-model-item>
		</div>
	</div>
</template>

<script>

import BHLoading from 'bh-mod/components/common/Loading'

export default {
	components:{ BHLoading },
    props:{
        value:{
            type:Object,
            default: () => ({})
        },
    },
    data(){
        return {
			loading:false,
            brokerObj:{},
			cities:[],
			builders:[],
        }
    },
    computed:{

    },
    watch:{
        brokerObj:{
            handler(val,oldVal){
				console.log('BROKER OBJ UPDATED')
                return this.$emit('input', val)
            },
			deep:true
        }
    },
	methods:{
		async onCityChange(){
			console.log('CITY CHANGE', this.selectedCities)
			if (this.brokerObj.cities && this.brokerObj.cities.length){
				let citiesQuery = this.brokerObj.cities.join(',')
				this.loading = true;
				this.$api.get(`/instances/:instance/instancesbycities?cities=${citiesQuery}`).then(({data}) => {
					console.log('BUILDERS COMING BACK', data)
					data = data.sort((a,b) => {
						if (a.locationOBJ.cityLabel < b.locationOBJ.cityLabel) return -1
						if (a.locationOBJ.cityLabel > b.locationOBJ.cityLabel) return 1
						return 0
					})
					this.builders = data
					this.loading = false;
				}).catch(err => {
					console.log('ERROR GETTING BUILDERS', err)
					this.loading = false;
				})
			} else {
				this.brokerObj.builders = []
				this.builders = []
			}
		},
	},
    async created(){
		this.loading = true;
		this.$api.get('/instances/:instance/cities').then(({data}) => {
			console.log('CITIES COMING BACK', data)
			this.cities = data
			this.loading = false;
		}).catch(err => {
			console.log('ERROR GETTING CITIES', err)
			this.loading = false;
		})

		if (this.value){
			this.brokerObj = this.value
			if (this.brokerObj.cities && this.brokerObj.cities.length){
				let citiesQuery = this.brokerObj.cities.join(',')
				this.loading = true;
				this.$api.get(`/instances/:instance/instancesbycities?cities=${citiesQuery}`).then(({data}) => {
					console.log('BUILDERS COMING BACK', data)
					data = data.sort((a,b) => {
						if (a.locationOBJ.cityLabel < b.locationOBJ.cityLabel) return -1
						if (a.locationOBJ.cityLabel > b.locationOBJ.cityLabel) return 1
						return 0
					})
					this.builders = data
					this.loading = false;
				}).catch(err => {
					console.log('ERROR GETTING BUILDERS', err)
					this.loading = false;
				})
			} else {
				this.brokerObj.builders = []
				this.builders = []
			}
		}
    },
    mounted(){
        // if (!this.value) this.instance = this.instances[0].value
    }
}
</script>

<style scoped lang="scss">

</style>
